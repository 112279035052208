import { useState, useEffect } from "react";


export const useLicenseKeyValidation = ({ licenseKey = "" }) => {
    const [validLength, setValidLength] = useState(null);
    const [hasNumber, setHasNumber] = useState(null);
    const [upperCase, setUpperCase] = useState(null);
    const [lowerCase, setLowerCase] = useState(null);
    const [validPassword, setValidPassword] = useState(null)


    useEffect(() => {
        setValidLength(licenseKey.length >= 8 ? true : false);
        setUpperCase(licenseKey.toLowerCase() !== licenseKey);
        setLowerCase(licenseKey.toUpperCase() !== licenseKey);
        setHasNumber(/\d/.test(licenseKey));
        setValidPassword(validLength && hasNumber && upperCase)

    }, [licenseKey]);
    return [validLength, hasNumber, upperCase, lowerCase, validPassword];


}

export function setLicenseKeyToken(key, value) {
    let ttl = 900000;
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}


export function getLicenseKeyToken(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}
