import React from 'react';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import { BsArrowRight } from "react-icons/bs"
import { Link } from 'react-router-dom';
import { analysisCollectionRef } from '../../hooks/firebase/fire-app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { customDateFormat } from '../../utilities';
import StorageImg from '../../Components/StorageImg/StorageImg';

const ViewDashboard = () => {
    const [allAnalysis, loading, error] = useCollectionData(analysisCollectionRef, { idField: 'id' });

    return (
        <>
            <AdminLayout activeMenu={1} title="Dashboard">
                <div className="container">
                    <h4 className="font-bold mt-5 mb-4">Previous Reports</h4>
                
                    <div className="row">
                    {!loading && !error && allAnalysis && allAnalysis.length > 0 && allAnalysis.map((info, index) => <ReportCard data={info} name="Sabereen Faruque" age="24" sex="Male" />)}

                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default ViewDashboard;


const ReportCard = ({ data, age, name, sex }) => {
    return (
        <div className="col-md-4 mb-4">
            <div className="card p-4">
                <h5 className="font-bold">{data?.name}</h5>
                <div className="d-flex justify-content-">
                    <p><span className="font-bold">Age:</span> {data?.age}</p>
                    <p className='ml-5'><span className="font-bold">Sex:</span> {data?.sex}</p>
                </div>
                <p><span className="font-bold">Date: </span>{customDateFormat(data.createdAt)}</p>
                <Link to={`/clinical-data-analysis/${data.id}`} className="btn mt-4 btn-primary">View all analysis <BsArrowRight /></Link>
            </div>
        </div>
    )
}