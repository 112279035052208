import React from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";
import { useHistory } from 'react-router-dom';
import fireApp, { registeredUsersCollection } from "../../hooks/firebase/fire-app";
import { useEffect } from 'react';

const ViewSignOut = () => {
    const { currentUser, setCurrentUser, setHasAccess, hasAccess } = useState(null);
    const history = useHistory();

    const signOutFunction = () => {
        fireApp.auth().signOut().then(res => {
            // window.location.reload()
            // setHasAccess(false);
            // setCurrentUser(null);
            // localStorage.removeItem("licenseKeyToken")
            // setCurrentUser(null);
            history.push("/")
        }).catch(err => {

        })
    }

    return (
        <>
            <AdminLayout activeMenu={7}>
                <div className="container d-flex align-items-center justify-content-center sign-out">
                    {/* <Spinner variant='primary' animation='grow' /> */}
                    <button onClick={signOutFunction} className="btn btn-danger btn-lg">Sign Out</button>
                </div>
            </AdminLayout>
        </>
    );
};

export default ViewSignOut;