import React from 'react';
import AdminLayout from '../../Components/adminLayout/AdminLayout';

const ViewDownloadClinicalResults = () => {
    return (
        <>
            <AdminLayout activeMenu={3}>
                <div className="container p-4">
                    <h4 className="text-center font-bold mb-">Download Clinical Results</h4>
                </div>
            </AdminLayout>
        </>
    );
};

export default ViewDownloadClinicalResults;