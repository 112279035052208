import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import ModalLoading from '../../Components/common/PreLoaders/ModalLoading';
import CompFrequencyAnalysis from './CompFrequencyAnalysis';
import CompTimeDomainAnalysis from './CompTimeDomainAnalysis';
import CompTimeFrequencyAnalysis from './CompTimeFrequencyAnalysis';
import { BsDownload } from "react-icons/bs"
import "./dashboard.scss"
import JsPDF from 'jspdf';
import StorageImg from '../../Components/StorageImg/StorageImg';
import Table from 'react-bootstrap/Table';
import { analysisCollectionRef } from '../../hooks/firebase/fire-app';
import CompAnnotations from './CompAnnotations';
import AnalysisSourceLocalization from './AnalysisSourceLocalization';
import AnalysisConnectivity from './AnalysisConnectivity';
import CompPDFDoc from '../ViewDownloadReports/CompPDFDoc';


const ViewReportsClinicalAnalysis = () => {
    // const { analysisID } = useParams();
    const [analysisID, setAnalysisID] = useState("KtZzZo0URXcr4qklZMXH");
    const [modalText, setModalText] = useState("Time Domain Analysis")
    const [modalShow, setModalShow] = React.useState(false);

    const [showAnalysis, setShowAnalysis] = useState("one");
    const [showOne, setShowOne] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [showFour, setShowFour] = useState(false);
    const [showFive, setShowFive] = useState(false);
    const [showSix, setShowSix] = useState(false);
    const [analysisData, setAnalysisData] = useState(null);

    const analysisController = (analysisNumb, text) => {
        setModalText(text)
        setModalShow(true);
        setTimeout(() => {
            setModalShow(false);
            analysisNumb(true);
        }, 4500)
    }
    useEffect(() => {
        if (!showOne) {
            setModalShow(true);
            setTimeout(() => {
                setModalShow(false);
                setShowOne(true);
            }, 4500)
        }
        analysisCollectionRef.doc(analysisID).get().then(doc => {
            setAnalysisData(doc.data())
        })
    }, [])
    const generatePDF = () => {

        const report = new JsPDF('landscape', 'pt', 'a4');
        report.html(document.querySelector('#report')).then(() => {
            report.save('report.pdf');
        });
    }
    return (
        <>
            <AdminLayout activeMenu={2} title={`Clinical Data Analysis: ${analysisID}`}>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="">
                                <button onClick={() => setShowAnalysis("one")} className="mr-2 btn btn-primary">
                                    Time
                                </button>
                                {true && <button onClick={() => setShowAnalysis("frequency")} className=" mr-2 btn btn-primary">
                                    Frequency
                                </button>}
                                {true && <button onClick={() => setShowAnalysis("time-frequency")} className=" mr-2 btn btn-primary">
                                    Time Frequency
                                </button>}
                                {true && <button onClick={() => setShowAnalysis("source")} className=" mr-2 btn btn-primary">
                                    Source Localization
                                </button>}
                                {true && <button onClick={() => setShowAnalysis("connectivity")} className=" mr-2 btn btn-primary">
                                    Connectivity
                                </button>}
                                {true && <CompPDFDoc/> }

                                {showThree && analysisID && <CompPDFDoc analysisID={analysisID} />}
                                {/* {showThree && <a target="_blank" href="/analysis/reports/Dashboard Report-3.pdf" className="btn btn-danger btn-lg text-white font-14 mt-5" download>DOWNLOAD REPORT(PDF) <BsDownload /></a>} */}
                                {/* {showThree && <button onClick={generatePDF} className="btn btn-danger btn-lg text-white font-14 mt-5">DOWNLOAD REPORT(PDF) <BsDownload/></button> } */}
                            </div>
                        </div>
                        <div className="col-md-10 offset-md-1" id='report'>
                            <div className="card mt-4 px-4 py-3 ml-3">
                                {
                                    showAnalysis === "one" && <div className="row">
                                        <div className="col-md-6">
                                            <StorageImg analysisData={analysisData} annotation={"antPowerTopo"} name="power_topo_plot.jpg" caption="Power topo plot" />
                                        </div>
                                        <div className="col-md-6">
                                            <StorageImg analysisData={analysisData} annotation="antEvokedPlot" name="evoked_plot.jpg" caption="Evoked Plot" />
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <StorageImg analysisData={analysisData} annotation="antCZPlot" name="cz_plot.jpg" caption="CZ plot" />
                                                </div>
                                                <div className="col-md-12">
                                                    <Table striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>P30</th>
                                                                <th>N45</th>
                                                                <th>P60</th>
                                                                <th>N100</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Latency</td>
                                                                <td>{analysisData && analysisData.czPlot.amplitude[0]}</td>
                                                                <td>{analysisData && analysisData.czPlot.amplitude[1]}</td>
                                                                <td>{analysisData && analysisData.czPlot.amplitude[2]}</td>
                                                                <td>{analysisData && analysisData.czPlot.amplitude[3]}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Amplitude</td>
                                                                <td>{analysisData && analysisData.czPlot.latency[0]}</td>
                                                                <td>{analysisData && analysisData.czPlot.latency[1]}</td>
                                                                <td>{analysisData && analysisData.czPlot.latency[2]}</td>
                                                                <td>{analysisData && analysisData.czPlot.latency[3]}</td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 mt-3">
                                            <CompAnnotations />
                                        </div> */}
                                    </div>
                                }
                                {
                                    showAnalysis === "frequency" && <div className="row d-fle justify-cntent-center">
                                        <div className="col-3"><p className="font-22">ERSC: </p></div>
                                        <div className="col-md-6">
                                            <StorageImg analysisData={analysisData} annotation="antERSC" name="ersc.jpg" caption="" />
                                        </div>

                                        <div className="col-md-12">
                                            <h5>Topological Distribution</h5>
                                            <div className="row">
                                                <div className="col-md-6 px-5">
                                                    <StorageImg analysisData={analysisData} annotation="antTopoBeta" name="topo_beta.gif" />
                                                </div>
                                                <div className="col-md-6 px-5">
                                                    <StorageImg analysisData={analysisData} annotation="antTopoGamma" name="topo_gamma.gif" />
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div className="col-12 mt-3">
                                            <CompAnnotations />
                                        </div> */}
                                    </div>
                                }
                                {
                                    showAnalysis === "time-frequency" && <div className="row">
                                        <div className="co-8">
                                            <div className="mx-5 px-5"><StorageImg analysisData={analysisData} annotation="antTimeFrequency" name="time-frequency.jpg" /></div>
                                        </div>
                                        {/* <div className="col-12 mt-3">
                                            <CompAnnotations />
                                        </div> */}
                                    </div>
                                }
                                {
                                    showAnalysis === "source" && <AnalysisSourceLocalization analysisData={analysisData} analysisID={analysisID} />
                                }
                                {
                                    showAnalysis === "connectivity" && <div className="text-center">
                                        <AnalysisConnectivity analysisData={analysisData} analysisID={analysisID} />
                                        {analysisID && <CompPDFDoc analysisID={analysisID} />}

                                    </div>
                                }
                            </div>
                            {/* {showAnalysis==="one" && <CompTimeDomainAnalysis />
                            } */}
                            {/* {showAnalysis==="one" && <CompTimeDomainAnalysis />
                            } */}
                            {/* {showOne && !showTwo && <button onClick={() => analysisController(setShowTwo, "Frequency Analysis")} className="btn btn-primary high-mb mt-4">Start Frequency Analysis</button>} */}

                            {/* {showTwo && <CompFrequencyAnalysis />} */}

                            {showAnalysis === "two" && <button onClick={() => analysisController(setShowThree, "Time Frequency Analysis")} className="btn btn-primary high-mb mt-4">Start Time Frequency Analysis</button>}

                            {showAnalysis === "three" && <CompTimeFrequencyAnalysis />}
                        </div>

                    </div>
                </div>

            </AdminLayout>
            <ModalLoading show={modalShow} modalText={modalText} onHide={() => setModalShow(false)} />
        </>
    );
};

export default ViewReportsClinicalAnalysis;