import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './Components/router/ScrollToTop';
import Main from './views/Main';

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Main />
      </Router>
    </>
  );
}
export default App;
