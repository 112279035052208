const pdfImages = {
    plotPowerTopo: "/reports/KtZzZo0URXcr4qklZMXH/power_topo_plot.jpg",
    plotEvoked: "/reports/KtZzZo0URXcr4qklZMXH/evoked_plot.jpg",
    plotCZ: "/reports/KtZzZo0URXcr4qklZMXH/cz_plot.jpg",
    plotERSC: "/reports/KtZzZo0URXcr4qklZMXH/ersc.jpg",
    plotTopoBeta: "/reports/KtZzZo0URXcr4qklZMXH/topo-beta-gama.png",
    plotTimeFrequency: "/reports/KtZzZo0URXcr4qklZMXH/time-frequency.jpg",
    plotSourceDefault: "/reports/KtZzZo0URXcr4qklZMXH/source_default.jpg"
}

export default pdfImages;