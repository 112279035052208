import React, { Children } from 'react';
import { Link } from 'react-router-dom';
import "./style.scss"
import { FaHome } from "react-icons/fa";
import TopNavigationAdmin from './TopNavigationAdmin';
import { useForm } from 'react-hook-form';
import AdminSidebar from './AdminSidebar';
import adminSidebarItems from './adminSidebarData';


const AdminLayout = ({ children, activeMenu, title }) => {
    const { register, handleSubmit } = useForm();
    const sideBarItems = adminSidebarItems;
    sideBarItems[1].status = " active"

    return (
        <>
            <div>
                <div className="d-flex">
                    <div className="width-side">
                        <AdminSidebar sideBarItems={sideBarItems} />
                    </div>
                    <div className="width-main bg-admin px-4 py-4">
                        <TopNavigationAdmin title={title} />
                        {children}
                    </div>
                </div>
            </div>
            {/* <div class="my-sidebar">
                <div className="my-5 text-center px-3">
                    <img className="img-fluid" src="/logo/myelinh-logo.png" alt="" />
                </div>
                <MenuItem active={activeMenu === 1} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                            <FaHome />
                        </span>
                        <Link to="/" className='menu-link' >Dashboard</Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 2} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                            <FaHome />
                        </span>
                        <Link to="/clinical-data-analysis" className='menu-link' >Clinical Data Analysis</Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 3} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                        </span>
                        <Link to="/download-clinical-results" className='ml-3 menu-link' >
                            Time Domain Analysis
                        </Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 3} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                        </span>
                        <Link to="/download-clinical-results" className='ml-3 menu-link' >
                            Frequency Analysis
                        </Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 3} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                        </span>
                        <Link to="/download-clinical-results" className='ml-3 menu-link' >
                            Power Plot Joint
                        </Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 3} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                        </span>
                        <Link to="/link" className='ml-3 menu-link' >
                            Source Localization
                        </Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 3} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                        </span>
                        <Link to="/link" className='ml-3 menu-link' >
                            MRI Coordinates: Subjects
                        </Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 3} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                        </span>
                        <Link to="/link" className='ml-3 menu-link' >

                        </Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 3} >
                    <div className="d-flex align-items-center">
                        <span className="icon">
                        </span>
                        <Link to="/link" className='ml-3 menu-link' >

                        </Link>
                    </div>
                </MenuItem>


                <MenuItem className="mt-2" active={activeMenu === 4}>
                    <div className="d-flex align-items-center">
                        <span className="icon">
                            <FaHome />
                        </span>
                        <Link to="/profile" className='menu-link' >Profile</Link>
                    </div>
                </MenuItem>
                <MenuItem className="" active={activeMenu === 5}>
                    <div className="d-flex align-items-center">
                        <span className="icon">
                            <FaHome />
                        </span>
                        <Link to="/update-info" className='menu-link' >Update Profile</Link>
                    </div>
                </MenuItem>
                <MenuItem className="" active={activeMenu === 6}>
                    <div className="d-flex align-items-center">
                        <span className="icon">
                            <FaHome />
                        </span>
                        <Link to="/feedback" className='menu-link' >Give Feedback</Link>
                    </div>
                </MenuItem>
                <MenuItem active={activeMenu === 7}>
                    <div className="d-flex align-items-center">
                        <span className="icon">
                            <FaHome />
                        </span>
                        <Link to="/sign-out" className='menu-link' >Sign Out</Link>
                    </div>
                </MenuItem>



            </div>

            <div class="content">
                <TopNavigationAdmin title={title} />
                {children}
            </div> */}

        </>
    );
};

export default AdminLayout;

const MenuItem = ({ active, children, className }) => {
    return (
        <>
            <div className={`menu-item ${active && "active"} ${className}`}>
                {children}
            </div>
        </>
    )
}