import React from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import { BsArrowRight } from "react-icons/bs"
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { analysisCollectionRef } from '../../hooks/firebase/fire-app';
import { customDateFormat } from '../../utilities';

const ViewSearchReports = () => {
    const [allAnalysis, loading, error] = useCollectionData(analysisCollectionRef, { idField: 'id' });
    return (
        <>
            <AdminLayout activeMenu={1} title="Search Reports">
                <div className="container">
                    <h4 className="font-bold mt-5 mb-4">Previous Reports</h4>
                    <div className="row mb-5 d-flex align-items-center">
                        <div className="col-5">
                            {/* <label className="mb-3 font-weight-bold">User Name</label> */}
                            <input type="text" name="name" placeholder="write the email of the user" id="name" className="form-control py-4" />
                        </div>


                        <div className="col-4">
                            <button type="submit" className="btn btn-primary px-5 py-3">Search Reports</button>
                        </div>
                    </div>

                    <div className="row">
                        {!loading && !error && allAnalysis && allAnalysis.length > 0 && allAnalysis.map((info, index) => <ReportCard data={info} name="Sabereen Faruque" age="24" sex="Male" />)}

                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default ViewSearchReports;

const ReportCard = ({ data, age, name, sex }) => {
    return (
        <div className="col-md-4 mb-4">
            <div className="card p-4">
                <h5 className="font-bold">{data?.name}</h5>
                <div className="d-flex justify-content-">
                    <p><span className="font-bold">Age:</span> {data?.age}</p>
                    <p className='ml-5'><span className="font-bold">Sex:</span> {data?.sex}</p>
                </div>
                <p><span className="font-bold">Date: </span>{customDateFormat(data.createdAt)}</p>
                <Link to={`/clinical-data-analysis/${data.id}`} className="btn mt-4 btn-primary">View all analysis <BsArrowRight /></Link>
            </div>
        </div>
    )
}