import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { analysisCollectionRef } from '../../hooks/firebase/fire-app';

const CompAnnotations = ({ annotation, analysisID, analysisData }) => {
    const [annotationText, setAnnotationText] = useState("");

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const annotationUpdate = (data, e) => {
        alert(data.antText)
        analysisCollectionRef.doc(analysisID).update({[annotation]: data.antText})
        setAnnotationText(data.antText)
    }
    useEffect(()=>{
        if(analysisData && analysisData[annotation]){
            setAnnotationText(analysisData[annotation])
        }
    },[analysisData])

    return (
        <>
            <div className="col-md-12 mb-3">
                <form onSubmit={handleSubmit(annotationUpdate)} className="row">
                    {/* <div className="col-12">
                            <h5 className="font-bold">Annotations:</h5>
                        </div> */}

                    <InputWrapper label="" id="feedback">
                        <p className="font-14">{annotationText}</p>
                        <textarea  rows="3" type="text" className="form-control font-14 textarea-outline rounded-0" id="antText" 
                            {...register("antText", { required: true })} >
                        </textarea>
                    </InputWrapper>
                    <div className="col-md-12 text-right">
                        <button type='submit' className="btn font-14 btn-sm btn-primary">Save</button>
                    </div>
                </form>
            </div>

        </>
    );
};

export default CompAnnotations;

const InputWrapper = ({ col, children, label, id }) => {
    return (
        <div className={`mb-1 col-md-${col ? col : 12}`}>
            {/* <label className="form-label text-dark fw-600" htmlFor={id}>
                {label}
            </label> */}
            {children}
        </div>
    )
}