import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const ModalLoading = (props) => {
    const {modalText} = props
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="p-5 text-center">
                    <h4 className="text-center font-bold">Process running: {modalText}</h4>
                    <p className="text-center mb-4">Please wait for a few seconds...</p>
<Spinner animation='border'/>
                <div className="d-flex justify-content-center">

                </div>
                </div>

            </Modal>
        </>
    );
};

export default ModalLoading;