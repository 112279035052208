import React, { useState, useContext, useEffect } from "react";
// import fireApp from "../firebase/fire-app";
import fireApp, { fireStoreDB, registeredUsersCollection, userInfoCollectionRef } from "./firebase/fire-app";
import { getLicenseKeyToken } from "./useLicenseKeyValidation";

export const AuthContext = React.createContext();


export const AuthContextProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(null);
    const [licenseVerified, setLicenseVerified] = useState(false);
    const [userID, setUserId] = useState(null);
    const [registered, setRegistered] = useState(false);
    const [pending, setPending] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    

    useEffect(() => {
        if (currentUser && licenseVerified) {
            setHasAccess(true)
        }
    }, [currentUser, licenseVerified])

    useEffect(() => {
        const storedToken = getLicenseKeyToken('licenseKeyToken');
        if (storedToken) {
            setLicenseVerified(true);
        } else {
            setLicenseVerified(false)
        }
    }, [])

    useEffect(() => {
        setPending(true)
        fireApp.auth().onAuthStateChanged((usr) => {
            registeredUsersCollection.doc(usr.uid).get().then((doc) => {
                if (!doc.exists) {
                    setRegistered(false);
                    setCurrentUser(null);
                    setPending(false)
                } else {
                    const regUser = doc.data();
                    setRegistered(regUser.approved);
                    setCurrentUser(regUser);
                    setPending(false)
                }
            })

        });
    }, []);





    return (
        <AuthContext.Provider value={{ licenseVerified, setLicenseVerified, currentUser, pending, setCurrentUser, registered, hasAccess, setRegistered }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
