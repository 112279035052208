import { useEffect } from 'react';
import { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SectionLoading from '../Components/common/PreLoaders/SectionLoading';
import { useAuth } from '../hooks/useAuth';
import { getLicenseKeyToken } from '../hooks/useLicenseKeyValidation';
import ViewClinicalAnalysis from './ClinicalAnalysis/ViewClinicalAnalysis';
import ViewDownloadClinicalResults from './ClinicalAnalysis/ViewDownloadClinicalResults';
import ViewReportsClinicalAnalysis from './ClinicalAnalysis/ViewReportsClinicalAnalysis';
import ViewHome from './Home/ViewHome';
import ViewRoot from './Home/ViewRoot';
import ViewSearchReports from './SearchReports/ViewSearchReports';
import ViewDashboard from './ViewDashboard/ViewDashboard';
import ViewDownloadReports from './ViewDownloadReports/ViewDownloadReports';
import ViewLicenseVerify from './ViewLogin/ViewLicenseVerify';
import ViewLogin from './ViewLogin/ViewLogin';
import ViewRegister from './ViewLogin/ViewRegister';
import ViewSignOut from './ViewLogin/ViewSignOut';
import ViewPdf from './ViewPdf';
import ViewContactAdmin from './ViewUserInfo/ViewContactAdmin';
import ViewUpdateUser from './ViewUserInfo/ViewUpdateUser';
import ViewUserProfile from './ViewUserInfo/ViewUserProfile';

const Main = () => {
    const { hasAccess, currentUser, licenseKeyVerified, licenseVerified } = useAuth();
    const [giveAccess, setGiveAccess] = useState(true);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (currentUser && licenseVerified) {
            setTimeout(() => {
                setLoading(false)
                setGiveAccess(true)
            }, 2000)
        }
    }, [licenseVerified, currentUser])
    useEffect(() => {
    }, [])
    return (
        <>
            <>
                <Switch>
                    {<Route exact path="/">
                        <ViewRoot />
                    </Route>}
                    {giveAccess && <Route exact path="/update-profile">
                        <ViewUpdateUser />
                    </Route>}
                    {giveAccess && <Route exact path="/profile">
                        <ViewUserProfile />
                    </Route>}
                    {giveAccess && <Route exact path="/clinical-data-analysis">
                        <ViewClinicalAnalysis />
                    </Route>}
                    {giveAccess && <Route exact path="/download-clinical-results">
                        <ViewDownloadClinicalResults />
                    </Route>}
                    {/* {giveAccess && <Route exact path="/feedback">
                        <ViewUserFeedback />
                    </Route>} */}
                    {giveAccess && <Route exact path="/sign-out">
                        <ViewSignOut />
                    </Route>}
                    {giveAccess && <Route exact path="/contact">
                        <ViewContactAdmin />
                    </Route>}
                    {giveAccess && <Route exact path="/dashboard">
                        <ViewDashboard />
                    </Route>}
                    {<Route exact path="/search">
                        <ViewSearchReports />
                    </Route>}
                    {giveAccess && <Route exact path="/download-report">
                        <ViewDownloadReports />
                    </Route>}
                    {giveAccess && <Route exact path="/clinical-data-analysis/:analysisID">
                        <ViewReportsClinicalAnalysis />
                    </Route>}
                    <Route exact path="/sign-in">
                        <ViewLogin />
                    </Route>
                    <Route exact path="/register">
                        <ViewRegister />
                    </Route>
                    <Route exact path="/pdf">
                        <ViewPdf />
                    </Route>
                </Switch>
            </>
        </>
    );
};

export default Main;