import React from 'react';
import "./helper.css";
import "./login.css";
import "./social-icons.css"
import { Link, useHistory } from "react-router-dom";
import SocialIcons from './_SocialIcons';
import SignInLayout from './SignInLayout';
import { useForm } from "react-hook-form";
import fireApp, { registeredUsersCollection } from "../../hooks/firebase/fire-app";
import { useAuth } from '../../hooks/useAuth';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';

const ViewLogin = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { currentUser, setRegistered, setCurrentUser, hasAccess, setHasAccess, licenseVerified } = useAuth();
    const [loading, setLoading] = useState(false)

    const history = useHistory();

    const onFormSubmit = (data, e) => {
        setLoading(true)
        const email = data.email;
        const password = data.password;

        fireApp.auth().signInWithEmailAndPassword(email, password).then((userCredential) => {
            setTimeout(() => {
                alert("Authentication is successful. Let's go to the dashboard.")
                history.push("/dashboard");
            }, 2500)
        })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            })

    }

    return (
        <>
            <SignInLayout>
                <div className="col-md-5 d-flex align-items-center py-5">
                    <div className="container my-auto py-4 shadow-lg bg-white">
                        <div className="row">
                            <div className="col-11 col-lg-10 mx-auto">
                                <h3 className="text-9 fw-600 text-center my-3">Sign In </h3>
                                <p className="text-center mb-4">New to Myelin-H? <Link to="/register"><u>Create an
                                    Account</u></Link></p>
                                <form onSubmit={handleSubmit(onFormSubmit)} >
                                    <div className="mb-3">
                                        <label className="form-label text-dark fw-600" for="emailAddress">Username
                                            or Email Address</label>
                                        <input type="email"  {...register("email", { required: true })} className="form-control rounded-0" id="emailAddress"
                                            required placeholder="Enter Your Email" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label text-dark fw-600"
                                            for="loginPassword">Password</label>
                                        <input type="password" {...register("password", { required: false })} className="form-control rounded-0" id="loginPassword"
                                            required placeholder="Enter Password" />
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="form-check">
                                                <input id="remember-me" name="remember"
                                                    className="form-check-input rounded-0" type="checkbox" />
                                                <label className="form-check-label" for="remember-me">Keep me signed
                                                    in</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid my-4">
                                        {loading ? <button disabled className={`btn btn-dark btn-block rounded-0`} type="submit">Sign in
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </button> :
                                            <button className={`btn btn-dark btn-block rounded-0`} type="submit">
                                                Sign in
                                            </button>}

                                    </div>
                                </form>
                                <div className="d-flex align-items-center my-4">
                                    <hr className="flex-grow-1" />
                                    <span className="mx-2 text-2 text-muted">Follow us on</span>
                                    <hr className="flex-grow-1" />
                                </div>
                                <SocialIcons />
                            </div>
                        </div>
                    </div>
                </div>
            </SignInLayout>

        </>
    );
};

export default ViewLogin;