import React from 'react';

const CompTimeFrequencyAnalysis = () => {
    return (
        <>
            <div className="card px-4 py-3 ml-3 mt-4" id="frequency-analysis">
                <h3 className="font-bold mb-0  text-center">
                    2. Time Frequency Analysis
                </h3>
                <hr className='analysis' />
                <div className="mt-4">
                    <h4 className="font-bold mb-3 text-secondary">
                        i. Power Plot Joint:
                    </h4>
                    <img src="/analysis/3/1.png" alt="" className="img-fluid" />
                </div>
                {/* <div className="mt-4">
                    <h4 className="font-bold mb-3 text-secondary">
                        ii. Inter-Trial Coherence
                    </h4>
                    <img src="/analysis/3/2.png" alt="" className="img-fluid" />
                </div> */}
                
            </div>
        </>
    );
};

export default CompTimeFrequencyAnalysis;