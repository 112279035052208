import React from 'react';
import "./helper.css";
import "./login.css";
import "./social-icons.css"
import { Link } from "react-router-dom";
import SocialIcons from './_SocialIcons';
import SignInLayout from './SignInLayout';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import fireApp, { registeredUsersCollection } from "../../hooks/firebase/fire-app";
import { withRouter, useHistory, useLocation } from "react-router-dom";



const ViewRegister = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { currentUser, setRegistered, setCurrentUser } = useAuth();
    let history = useHistory();


    // password match
    // const [password, setPassword] = useState({
    //     firstPassword: "",
    //     secondPassword: "",
    // });
    // const setFirst = (event) => {
    //     setPassword({ ...password, firstPassword: event.target.value });
    // };
    // const setSecond = (event) => {
    //     setPassword({ ...password, secondPassword: event.target.value });
    // };



    const onFormSubmit = (data, e) => {

        const email = data.emailAddress;
        const password = data.firstPassword;

        if(password === data.secondPassword && email){
            const userInfo = {
                email: email,
                password: password,
            }
            fireApp.auth().createUserWithEmailAndPassword(email, password).then((userCredential) => {
                const user = userCredential.user;
                console.log(user)
                registeredUsersCollection.doc(user.uid).set({ email: email, uid: user.uid, infoUpdated: false }).then(() => {
                    history.push("/update-info");
    
                })
            })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                })
        }
        // fireApp.auth().signInWithEmailAndPassword(email, password).then((userCredential) => {
        //     const user = userCredential.user;
        //     console.log(user);
        //     setCurrentUser(user);
        //     setRegistered(true);
        //     router.push("/resources/modules");
        // })
        //     .catch((error) => {
        //         const errorCode = error.code;
        //         const errorMessage = error.message;
        //     })

    }
    return (
        <>
            <SignInLayout>
                <div className="col-md-5 d-flex align-items-center py-5">
                    <div className="container my-auto py-4 shadow-lg bg-white">
                        <div className="row">
                            <div className="col-11 col-lg-10 mx-auto">
                                <h3 className="text-9 fw-600 text-center my-3">Create an Account</h3>
                                <p className="text-center mb-4">Already have an account? <Link
                                    to="/sign-in"><u>Sign In</u></Link></p>
                                <form onSubmit={handleSubmit(onFormSubmit)} >
                                    <div className="mb-3">
                                        <label className="form-label text-dark fw-600" for="emailAddress">Email
                                            Address</label>
                                        <input type="email"  {...register("emailAddress", { required: true })} className="form-control rounded-0" id="emailAddress"
                                            required placeholder="Enter Your Email" />
                                    </div>
                                   
                                    <div className="mb-3">
                                        <label className="form-label text-dark fw-600"
                                            for="firstPassword">Password</label>
                                        <input type="password" {...register("firstPassword", { required: true })} className="form-control rounded-0" id="password"
                                            required placeholder="Enter Password" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label text-dark fw-600"
                                            for="secondPassword">Confirm Password</label>
                                        <input type="password" {...register("secondPassword", { required: true })} className="form-control rounded-0" id="secondPassword"
                                            required placeholder="Enter Password" />
                                    </div>
                                    <div className="d-grid my-4">
                                        <button className="btn btn-dark btn-block rounded-0" type="submit">Create
                                            Account</button>
                                    </div>
                                    <p className="text-center text-muted text-2 mb-0">By creating an account, you
                                        agree to Myelin-H <a href="#">Terms</a> and <a href="#">Privacy</a></p>
                                </form>
                                <div className="d-flex align-items-center my-4">
                                    <hr className="flex-grow-1" />
                                    <span className="mx-2 text-2 text-muted">Follow us on</span>
                                    <hr className="flex-grow-1" />
                                </div>
                                <SocialIcons />
                            </div>
                        </div>
                    </div>
                </div>
            </SignInLayout>

        </>
    );
};

export default ViewRegister;