import React from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import { BsArrowRight } from "react-icons/bs"
// import { PDFViewer } from '@react-pdf/renderer';
// import CompPDFDoc from './CompPDFDoc';

const ViewDownloadReports = () => {
    return (
        <>
            <AdminLayout activeMenu={1} title="Download Reports">
                <div className="container">
                    <h4 className="font-bold mt-5 mb-4">All Reports</h4>
                    <div className="row mb-5 d-flex align-items-center">
                        <div className="col-5">
                            {/* <label className="mb-3 font-weight-bold">User Name</label> */}
                            <input type="text" name="name" placeholder="write the email of the user" id="name" className="form-control py-4" />
                        </div>


                        <div className="col-4">
                            <button type="submit" className="btn btn-primary px-5 py-3">Search Reports</button>
                        </div>
                    </div>

                    <div className="mt-5">
                        {/* <PDFViewer width={"800"} height="1200">
                            <CompPDFDoc />
                        </PDFViewer> */}
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default ViewDownloadReports;



const ReportCard = ({ age, name, sex }) => {
    return (
        <div className="col-md-4 mb-4">
            <div className="card p-4">
                <h5 className="font-bold">{name}</h5>
                <div className="d-flex justify-content-">
                    <p><span className="font-bold">Age:</span> {age}</p>
                    <p className='ml-5'><span className="font-bold">Sex:</span> {sex}</p>
                </div>
                <p><span className="font-bold">Date: </span>December 3, 2022</p>
                <Link to="/clinical-data-analysis/B7z4HCnQKtIgdg2rEfIC" className="btn mt-4 btn-primary">Click to download PDF <BsArrowRight /></Link>
            </div>
        </div>
    )
}