import React, { useEffect, useState } from 'react';
import fireApp, { analysisCollectionRef, fireStorage } from '../../hooks/firebase/fire-app';
import { jsPDF } from "jspdf";
import pdfImages from '../../data/pdfdata';

const CompPDFDoc = () => {
    const analysisID = "KtZzZo0URXcr4qklZMXH";
    const [analysisData, setAnalysisData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [analysisImages, setAnalysisImages] = useState(null);

    const servicesStorageRef = fireApp.storage().ref();

    analysisCollectionRef.doc(analysisID).get()
        .then(doc => {
            setAnalysisData(doc.data());
            const info = doc.data();
            setAnalysisImages(pdfImages)
            setLoading(false);

        })


    const generatePDF = () => {
        if (analysisData && analysisImages) {
            const doc = new jsPDF();



            var imglink = "examples/images/Octonyan.jpg"
            doc.setFontSize(22);
            doc.text("1. Time Domain Analysis", 20, 25);

            doc.addImage("/logo/dark.png", "JPEG", 165, 6, 38, 15);

            doc.setFontSize(16);
            doc.text("Power topo plot", 20, 35);
            doc.addImage(analysisImages.plotPowerTopo, "JPEG", 20, 40, 80, 50);
            doc.text(analysisData.antPowerTopo, 20, 95);

            doc.setFontSize(16);
            doc.text("Evoked Plot", 110, 35);
            doc.addImage(analysisImages.plotEvoked, "JPEG", 110, 40, 80, 50);
            doc.text(analysisData.antEvokedPlot, 110, 95);


            doc.setFontSize(16);
            doc.text("CZ Plot", 20, 120);
            doc.addImage(analysisImages.plotCZ, "JPEG", 20, 125, 80, 50);
            doc.text(analysisData.antCZPlot, 20, 180);

            // page 2

            doc.addPage("a4")
            doc.addImage("/logo/dark.png", "JPEG", 165, 6, 38, 15);

            doc.setFontSize(22);
            doc.text("2. Frequency Analysis", 20, 25);

            doc.setFontSize(16);
            doc.text("ERSC plot", 20, 35);
            doc.addImage(analysisImages.plotERSC, "JPEG", 20, 40, 80, 50);
            doc.text(analysisData.antERSC, 20, 95);


            doc.setFontSize(16);
            doc.text("Topological distribution", 20, 120);
            doc.addImage(analysisImages.plotTopoBeta, "JPEG", 20, 125, 160, 70);
            doc.text(analysisData.antTopologicalDistribution, 20, 220);

            // page 3

            doc.addPage("a4")
            doc.addImage("/logo/dark.png", "JPEG", 165, 6, 38, 15);

            doc.setFontSize(22);
            doc.text("3. Time Frequency Analysis", 20, 25);

            doc.setFontSize(16);
            doc.text("Time frequency plot", 20, 35);
            doc.addImage(analysisImages.plotTimeFrequency, "JPEG", 20, 40, 80, 50);
            doc.text(analysisData.antTimeFrequency, 20, 95);


            // page 4
            doc.addImage("/logo/dark.png", "JPEG", 165, 6, 38, 15);

            doc.setFontSize(22);
            doc.text("4. Source Localization Analysis", 20, 120);

            doc.addImage(analysisImages.plotSourceDefault, "JPEG", 20, 125, 80, 50);
            doc.setFontSize(16);
            doc.text(analysisData.antSourceLocalization, 20, 180);
            doc.setFontSize(22);




            window.open(doc.output('bloburl'), '_blank');


        }


    }
    return (
        <>
            {!loading && analysisData && <button className="btn btn-danger" onClick={generatePDF}>
                Download Report (PDF)
            </button>
            }
        </>
    );
};


export default CompPDFDoc;