import React from 'react';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { feedbackCollectionRef, registeredUsersCollection } from '../../hooks/firebase/fire-app';
import { useAuth } from '../../hooks/useAuth';
const ViewContactAdmin = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { currentUser, setRegistered, setCurrentUser } = useAuth();
    const history = useHistory();

    const onFormSubmit = (data, e) => {


        data.uid = currentUser.uid;
        data.name = currentUser.name;

        setCurrentUser(data)
        if (currentUser) {
            feedbackCollectionRef.add(data).then(() => {
                console.log("Updated");
                alert("Thank you for your feedback!");
                history.push("/profile")
            })
        }

    }
    return (
        <>
            <AdminLayout title="Contact Admin">
                <div className="container p-4">
                    <h4 className="text-center font-bold mb-">Submit Your Feedback</h4>
                    {/* <h6 className="text-center font-bold mb-5">User ID: {currentUser.uid}</h6> */}
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7">
                            {currentUser && <form className='row' onSubmit={handleSubmit(onFormSubmit)} >
                                <InputWrapper label="Full Name" id="name">
                                    <input type="text"  {...register("name", { required: true })} className="form-control rounded-0" id="name"
                                        required placeholder="Enter Your name" />
                                </InputWrapper>
                                <InputWrapper label="Email" id="email">
                                    <input type="email"  {...register("email", { required: true })} className="form-control rounded-0" id="email"
                                        required placeholder="Enter Your Email" />
                                </InputWrapper>
                                <InputWrapper label="Feedback" id="feedback">
                                    <textarea rows="6" type="text"  {...register("feedback", { required: true })} className="form-control rounded-0" id="address"
                                        required placeholder="Your feedback">
                                    </textarea>
                                </InputWrapper>
                                <div className="col-12 text-center my-4">
                                    <button className="btn btn-dark btn-block rounded-0" type="submit">Submit Feedback</button>
                                </div>
                            </form>}
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default ViewContactAdmin;


const InputWrapper = ({ col, children, label, id }) => {
    return (
        <div className={`mb-3 col-md-${col ? col : 12}`}>
            <label className="form-label text-dark fw-600" htmlFor={id}>
                {label}
            </label>
            {children}
        </div>
    )
}