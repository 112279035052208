import React from 'react';
import "./helper.css";
import "./login.css";
import "./social-icons.css"
import { Link } from "react-router-dom";
import {  FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa'
import SocialIcons from './_SocialIcons';
import { useAuth } from '../../hooks/useAuth';

const SignInLayout = ({children}) => {
    const {currentUser} = useAuth();
    return (
        <>
            <section className="myelin-login-register">
                <div className="hero-wrap d-flex align-items-center">
                    <div className="hero-mask opacity-6 bg-dark"></div>
                    <div className="hero-bg hero-bg-scroll" style={{ backgroundImage: "url('/bg/bg-space.gif')" }} ></div>
                    <div className="hero-content w-100">
                        <div className="container-fluid">
                            <div className="row min-vh-100">
                                {/* side div */}
                                <div className="col-md-7 d-flex flex-column">
                                    <div className="row g-0 my-auto">
                                        <div className="col-11 col-sm-10 col-lg-9 mx-auto text-center">
                                            <div className="logo mt-5 mb-3"> <a href="index.html" title="Myelin-H">
                                                <img
                                                    src="myelinh-logo.png" alt="Myelin-H" /></a>
                                            </div>
                                            <h1 className="text-5 fw-400 text-white mb-5">
                                                Myelin-H provides groundbreaking software technology for remote monitoring and treatment of <br /> <strong><u>neurological disorders</u></strong>
                                            </h1>
                                            {currentUser && <p className="text-white">{currentUser.uid}</p> }
                                            <p className="text-white mb-0">Login to access Myelin-H Dashboard</p>
                                            {/* <p className="text-light text-2 mx-lg-5">Scan this with your camera or our mobile app to
                                                login instantly.
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                                {/* form */}

                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SignInLayout;