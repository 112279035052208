import React from 'react';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import { useAuth } from '../../hooks/useAuth';

const ViewUserProfile = () => {
    const { currentUser } = useAuth()
    return (
        <>
            <AdminLayout activeMenu={4} title="User Profile">
                {currentUser && <div className="container p-4">
                    <h4 className="text-center font-bold mb-">User Profile</h4>
                    <h6 className="text-center font-bold mb-5">User ID: {currentUser.uid}</h6>
                    <div className="row mb-2">
                        <div className="col-md-2">
                            <p className="font-bold">Name:</p>
                        </div>
                        <div className="col-md-5">
                            <p className='font-bold'>{currentUser.name}</p>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-2">
                            <p className="font-bold">Email:</p>
                        </div>
                        <div className="col-md-5">
                            <p className='font-bold'>{currentUser.email}</p>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-2">
                            <p className="font-bold">Phone:</p>
                        </div>
                        <div className="col-md-5">
                            <p className='font-bold'>{currentUser.phone}</p>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-2">
                            <p className="font-bold">Address:</p>
                        </div>
                        <div className="col-md-5">
                            <p className='font-bold'>{currentUser.address}</p>
                        </div>
                    </div>
                </div>}
            </AdminLayout>
        </>
    );
};

export default ViewUserProfile;