import React from 'react';
import {  FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa'

const SocialIcons = () => {
    return (
        <div className="row g-3 mb-4">
        <div className="col-sm-4 d-grid text-center">
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/myelinh/" className="btn btn-outline-linkedin btn-sm border-2 rounded-0 shadow-none">
                <span className="mr-2  d-inline-block"><FaLinkedinIn /> </span>LinkedIn
            </a>
        </div>
        <div className="col-sm-4 d-grid text-center">
            <a target="_blank" rel="noreferrer" href="https://twitter.com/MyelinH" className="btn btn-outline-google btn-sm border-2 rounded-0 shadow-none">
                <span className="mr-2  d-inline-block"><FaYoutube /></span>YouTube
            </a>
        </div>

        <div className="col-sm-4 d-grid text-center">
            <a target="_blank" rel="noreferrer" href="https://twitter.com/MyelinH" className="btn btn-outline-twitter btn-sm border-2 rounded-0 shadow-none">
                <span className="mr-2 d-inline-block"><FaTwitter /></span>twitter
            </a>
        </div>
    </div>
    );
};

export default SocialIcons;