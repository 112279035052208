import React from 'react';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import fireApp, { registeredUsersCollection } from "../../hooks/firebase/fire-app";
import { useAuth } from '../../hooks/useAuth';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';




const ViewUpdateUser = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { currentUser, setRegistered, setCurrentUser } = useAuth();
    const [userInfo, setUserInfo] = useState(null)
    const history = useHistory();

    const onFormSubmit = (data, e) => {

        const email = data.email;
        const password = data.password;
        data.uid = currentUser.uid;
        setCurrentUser(data)
        if (currentUser) {
            registeredUsersCollection.doc(currentUser.uid).update(data).then(() => {
                console.log("Updated");
                history.push("/profile")
            })
        }

    }
    useEffect(() => {
        if (currentUser) {
            registeredUsersCollection.doc(currentUser.uid).get().then((doc) => {
                const userData = doc.data();
                if(userData.uid){
                    setUserInfo(userData);
                }
                
            })
        }
    }, [currentUser])


    return (
        <>
            <AdminLayout activeMenu={5} >
                <div className="container p-4">
                    <h4 className="text-center font-bold mb-">Update Profile</h4>
                    <h6 className="text-center font-bold mb-5">User ID: {currentUser.uid}</h6>
                    {currentUser && <form className='row' onSubmit={handleSubmit(onFormSubmit)} >
                        <InputWrapper label="Full Name" id="name">
                            <input type="text"  {...register("name", { required: true })} className="form-control rounded-0" id="name"
                                required placeholder="Enter Your name" />
                        </InputWrapper>
                        <InputWrapper label="Email" id="email">
                            <input type="email"  {...register("email", { required: true })} className="form-control rounded-0" id="email"
                                required placeholder="Enter Your Email" />
                        </InputWrapper>
                        <InputWrapper label="Phone" id="phone">
                            <input type="text"  {...register("phone", { required: true })} className="form-control rounded-0" id="phone"
                                required placeholder="Enter  phone number" />
                        </InputWrapper>
                        <InputWrapper label="Address" id="address">
                            <input type="text"  {...register("address", { required: true })} className="form-control rounded-0" id="address"
                                required placeholder="Your address" />
                        </InputWrapper>
                        <div className="col-12 text-center my-4">
                            <button className="btn btn-dark btn-block rounded-0" type="submit">Save Information</button>
                        </div>
                    </form>}
                </div>

            </AdminLayout>
        </>
    );
};

export default ViewUpdateUser;

const InputWrapper = ({ col, children, label, id }) => {
    return (
        <div className="mb-3 col-md-6">
            <label className="form-label text-dark fw-600" htmlFor={id}>
                {label}
            </label>
            {children}
        </div>
    )
}