import React, { useEffect, useState } from 'react';
import fireApp, { analysisCollectionRef, fireStorage } from '../../hooks/firebase/fire-app';
import CompAnnotations from '../../views/ClinicalAnalysis/CompAnnotations';

const StorageImg = ({name, caption, annotation, analysisData}) => {
    const [imgName, setImgName] = useState(name);
    const analysisID = "KtZzZo0URXcr4qklZMXH";
    const [imgUrl, setImgUrl] = useState(null);
    const serviceStorageRef = fireApp.storage().ref();
    useEffect(()=>{
        setImgName(name)
    },[name])
    useEffect(() => {
        serviceStorageRef.child(`analysis/${analysisID}/${imgName?imgName:"power_topo_plot.jpg"}`).getDownloadURL()
            .then(url => {
                // analysisCollectionRef.doc("testid").update({[name]: url});
                setImgUrl(url)
            })
    }, [])
    return (
        <>
        <p>{caption}</p>
            {imgUrl && <img src={imgUrl} alt="" className="img-fluid" />}
            <CompAnnotations analysisID={analysisID} analysisData={analysisData} annotation={annotation} />
        </>
    );
};

export default StorageImg;