import React from 'react';
import { BiShoppingBag } from 'react-icons/bi'
import { AiOutlineUserAdd, AiOutlineUser, AiOutlineMessage } from 'react-icons/ai';
import { MdAdd, MdMessage, MdDashboard, MdFileDownload, MdMedicalServices, MdOutlineSearch } from 'react-icons/md';

const serviceList = <BiShoppingBag className="sidebar-icon" />
const addService = <MdAdd className="sidebar-icon" />
const addAdmin = <AiOutlineUserAdd className="sidebar-icon" />
const dashboard = <MdDashboard className="sidebar-icon" />
const medical = <MdMedicalServices className="sidebar-icon" />
const search = <MdOutlineSearch className="sidebar-icon" />
const download = <MdFileDownload className="sidebar-icon" />
const user = <AiOutlineUser className="sidebar-icon" />
const message = <MdMessage className="sidebar-icon" />
const feedback = <AiOutlineMessage className="sidebar-icon" />

const adminSidebarItems = [
    {
        id: 1,
        icon: dashboard,
        text: "Dashboard",
        url: "/dashboard",
    },
    {
        id: 2,
        icon: medical,
        text: "Clinical Data Analysis",
        url: "/clinical-data-analysis",

    },
    {
        id: 2,
        icon: search,
        text: "Search Report",
        url: "/search",

    },
    {
        id: 2,
        icon: download,
        text: "Download Reports",
        url: "/download-report",

    },
    {
        id: 3,
        icon: user,
        text: "Profile",
        url: "/profile"
    },
    {
        id: 3,
        icon: addAdmin,
        text: "Update Profile",
        url: "/update-profile"
    },
    {
        id: 3,
        icon: message,
        text: "Contact Admin",
        url: "/contact"
    },
    {
        id: 3,
        icon: feedback,
        text: "Leave Feedback",
        url: "/feedback"
    },
]
export default adminSidebarItems;