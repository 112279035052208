import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SectionLoading from '../../Components/common/PreLoaders/SectionLoading';
import { useAuth } from '../../hooks/useAuth';
import { getLicenseKeyToken } from '../../hooks/useLicenseKeyValidation';
import ViewDashboard from '../ViewDashboard/ViewDashboard';
import ViewLicenseVerify from '../ViewLogin/ViewLicenseVerify';
import ViewLogin from '../ViewLogin/ViewLogin';

const ViewRoot = () => {
    const { hasAccess, currentUser} = useAuth();
    const [licenseKeyVerified, setLicenseKeyVerified] = useState(null)
    
    const [giveAccess, setGiveAccess] = useState(true);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const storedToken =  getLicenseKeyToken('licenseKeyToken');
        if(storedToken === "hVLQi3KFvdmlI2dQyU2h"){
            setLoading(false);
            setLicenseKeyVerified(true);
        }else{
            setLoading(false);
        }

    }, [])

    return (
        <>
            {/* {loading && <SectionLoading/>} */}
        {loading ? <SectionLoading/>: currentUser && licenseKeyVerified ? <ViewDashboard/>: licenseKeyVerified ? <ViewLogin/> : <ViewLicenseVerify setLicenseKeyVerified={setLicenseKeyVerified}/> }
        </>
    );
};

export default ViewRoot;