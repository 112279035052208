import React from 'react';
import { useState } from 'react';
import "./TopNavigation.scss";
import { FaBars } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Avatar from 'react-avatar';

const TopNavigationAdmin = ({ title }) => {
    const LogoBrand = "/logo/dark.png"
    const [responsive, setResponsive] = useState("topnav")
    const { currentUser } = useAuth();
    // const navFunction = () => {
    //     console.log("helo");
    //     if (responsive === "topnav") {
    //         setResponsive("topnav responsive")
    //         console.log("helo")
    //     } else {
    //         setResponsive("topnav")
    //     }
    // }
    const avPhoto = `https://lh3.googleusercontent.com/a-/AOh14GgmF7mCtI1gAQJqNlb7dF8dbM9lpen1qEBSRY6w=s96-c`
    return (
        <>
            <div className="top-navigation py-3">
                {/* <div className="width-side pl-5"><a href="/"><img src={LogoBrand} alt="creative-agency" className="admin-logo" /> </a></div> */}
                <div className="width-main d-flex justify-content-between align-items-center px-5">
                    <h4 className="mb-0 font-bold">{title} </h4>
                    <div className="d-flex align-items-center">
                        <p className="font-weight-bold mb-0 mr-4">{currentUser ? currentUser.name : "None"} </p>

                        {currentUser && <Avatar googleId="118096717852922241760" src={avPhoto} size="40" round={true} />
                        }
                    </div>
                </div>
            </div>

            {/* <div className={responsive} id="myTopnav">
                <h3 className='mr-5 pt-4 pb-3 pl-5 font-bold' to="/profile">{title}</h3>
            </div> */}




        </>
    );
};

export default TopNavigationAdmin;