import firebase from "firebase";
import "firebase/auth";
import firebaseConfig from "./firebase-config";


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
//   firebase
//     .firestore()
//     .enablePersistence()
//     .catch((err) => {
//         if (err.code === "failed-precondition") {
//             console.log(err);
//         } else console.log(err);
//     });

export default firebase;
export const fireStoreDB = firebase.firestore();
export const fireDatabase = firebase.database();
export const fireStorage = firebase.storage();





export const analysisCollectionRef = fireStoreDB.collection("analysis");
export const insightsCollectionRef = fireStoreDB.collection("insights");
export const newsCollectionRef = fireStoreDB.collection("news");
export const caseCollectionRef = fireStoreDB.collection("caseStudies");

export const websiteDataRef = fireStoreDB.collection("websiteData");
export const onlineTutorialsCollection = fireStoreDB.collection('onlineTutorials');
export const researchesCollectionRef = fireStoreDB.collection('researches');

export const userInfoCollectionRef = fireStoreDB
    .collection("userTypes");

export const writersCollectionRef = fireStoreDB.collection("writers");
export const appointmentsCollectionRef = fireStoreDB.collection("appointments");

export const registeredUsersCollection = fireStoreDB.collection("userMetadata");
export const individualResources = fireStoreDB.collection("individualResources");
export const videosCollectionRef = fireStoreDB.collection("gallery").doc('videos').collection('videos');

export const resourcesCollectionRef = fireStoreDB.collection("resources");


export const feedbackCollectionRef = fireStoreDB.collection("Feedbacks")
