import React from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import fireApp, { fireStoreDB, analysisCollectionRef } from "../../hooks/firebase/fire-app";

const CompAnalyzeMultiple = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const serviceStorageRef = fireApp.storage().ref().child('analysis');

    const formSubmission = (data, e) => {
        console.log(data);
        const rawFile = data.rawFile[0];
        const rawFileRef = serviceStorageRef.child(rawFile.name);
        rawFileRef.put(rawFile).then(res => {
            rawFileRef.getDownloadURL().then(res => {
                data.fileOne = res
                data.icon = res;
                analysisCollectionRef.add(data).then(() => {
                    console.log("added")
                })
            }).catch(err => console.log(err))

        })

    }
    return (
        <>
            <div className="mt-3 container">
                <form onSubmit={handleSubmit(formSubmission)} >
                    <div className="row">
                        <div className="col-7 mb-4">
                            <label className="mb-2 font-weight-bold">User Name</label>
                            <input type="text" name="name" placeholder="Name" id="name" className="form-control py-4" {...register("name", { required: true })} />
                        </div>
                        <div className="col-5 mb-4">
                            <label className="mb-2 font-weight-bold">User ID</label>
                            <input type="text" name="userID" placeholder="userID" id="userID" className="form-control py-4" {...register("userID")} />
                        </div>
                        <div className="col-4 mb-4">
                            <label className="mb-2 font-weight-bold">Email</label>
                            <input type="email" name="email" placeholder="email" id="email" className="form-control py-4" {...register("email")} />
                        </div>
                        <div className="col-4 mb-4">
                            <label className="mb-2 font-weight-bold">Age</label>
                            <input type="age" name="age" placeholder="age" id="age" className="form-control py-4" {...register("age")} />
                        </div>
                        <div class="custom-file col-4 mt-4 mx-3">
                            <input type="file" class="custom-file-input" id="customFile" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                        <div class="custom-file col-4 mt-4 mx-3">
                            <input type="file" class="custom-file-input" id="customFile" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                        <div class="custom-file col-4 mt-4 mx-3">
                            <input type="file" class="custom-file-input" id="customFile" />
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>

                        {/* <div className="col-6">
                            <label className="mb-3 font-weight-bold">Upload</label>
                            <input type="file" name="file1" placeholder="file1" id="file1" className="form-control py-4" {...register("file1")} />
                        </div> */}

                        <div className="col-12 mt-4">
                            <button type="submit" className="btn btn-primary px-5 py-3">Start Analysis</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default CompAnalyzeMultiple;