import React from 'react';
import "./helper.css";
import "./login.css";
import "./social-icons.css"
import { Link, useHistory } from "react-router-dom";
import SocialIcons from './_SocialIcons';
import SignInLayout from './SignInLayout';
import { useForm } from "react-hook-form";
import fireApp, { registeredUsersCollection } from "../../hooks/firebase/fire-app";
import { useAuth } from '../../hooks/useAuth';
import { useState } from 'react';
import { getLicenseKeyToken, setLicenseKeyToken, useLicenseKeyValidation } from '../../hooks/useLicenseKeyValidation';
import { Spinner } from 'react-bootstrap';
import { useEffect } from 'react';

const ViewLicenseVerify = ({setLicenseKeyVerified}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { currentUser, setRegistered, setCurrentUser, licenseVerified } = useAuth();
    const fixedKey = "hVLQi3KFvdmlI2dQyU2h"
    const [licenseKey, setLicenseKey] = useState("");
    const [
        validLength,
        hasNumber,
        upperCase,
        lowerCase,
    ] = useLicenseKeyValidation({
        licenseKey: licenseKey
    });

    const onFormSubmit = (data, e) => {
        setLoading(true);
        if (data.licenseKey === fixedKey) {
            setLicenseKeyToken('licenseKeyToken', data.licenseKey);
            setLicenseKeyVerified(true);
            setLoading(false);
        }

        setTimeout(() => {
            if (data.licenseKey === fixedKey) {
                history.push('/sign-in')
            }
        }, 1200)
    }
    // useEffect(() => {
    //     const storedToken = getLicenseKeyToken('licenseKeyToken');
    //     if (licenseVerified) {
    //         history.push('/sign-in')
    //     }
    // }, [])
    return (
        <>
            <SignInLayout>
                <div className="col-md-5 d-flex align-items-center py-5">
                    <div className="container my-auto py-4 shadow-lg bg-white">
                        <div className="row">
                            <div className="col-11 col-lg-10 mx-auto">
                                <h3 className="text-9 fw-600 text-center my-3 pb-4">
                                    Verify your License Key
                                </h3>
                                {/* <p className="text-center mb-4">New to Myelin-H? <Link to="/register"><u>Create an
                                    Account</u></Link></p> */}
                                <form onSubmit={handleSubmit(onFormSubmit)} >
                                    <div className="mb-3">
                                        {/* <label className="form-label text-dark fw-600" for="licenseKey">
                                            Your License Key
                                            </label> */}
                                        <input type="text"  {...register("licenseKey", { required: true })} className="form-control rounded-0" id="licenseKey"
                                            required placeholder="your license key" />
                                    </div>
                                    {licenseKey && <div className="row">
                                        <div className="col-md-9 offset-md-3">
                                            <ul>
                                                {!validLength && <li>
                                                    Length(8): {validLength ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>}
                                                {!hasNumber && <li>
                                                    Numeric value: {hasNumber ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>}
                                                {!upperCase && <li>
                                                    UpperCase: {upperCase ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>}
                                                {!lowerCase && <li>
                                                    LowerCase: {lowerCase ? <span className="text-success">True</span> : <span className="text-danger">False</span>}
                                                </li>}

                                            </ul>
                                        </div>
                                    </div>}

                                    <div className="d-grid my-4">
                                        {loading ? <button disabled className={`btn btn-dark btn-block rounded-0`} type="submit">Verify Key
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </button> :
                                            <button className={`btn btn-dark btn-block rounded-0`} type="submit">
                                                Verify Key

                                            </button>}
                                    </div>
                                </form>
                                <div className="d-flex align-items-center my-4">
                                    <hr className="flex-grow-1" />
                                    <span className="mx-2 text-2 text-muted">Follow us on</span>
                                    <hr className="flex-grow-1" />
                                </div>
                                <SocialIcons />
                            </div>
                        </div>
                    </div>
                </div>
            </SignInLayout>

        </>
    );
};

export default ViewLicenseVerify;