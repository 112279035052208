import React from 'react';
import StorageImg from '../../Components/StorageImg/StorageImg';

const AnalysisConnectivity = ({analysisData, analysisID}) => {
    return (
        <>
            <div className="row">
                <div className="col-md-4 text-center">
                    <h5>Overall</h5>
                    <StorageImg analysisData={analysisData} annotation="antConnectivityOverall" name="overall_connectivity.jpg" />
                </div>
                <div className="col-md-4 text-center">
                    <h5>Beta</h5>
                    <StorageImg analysisData={analysisData} annotation="antConnectivityBeta" name="beta_connectivity.jpg" />
                </div>
                <div className="col-md-4 text-center">
                    <h5>Gamma</h5>
                    <StorageImg analysisData={analysisData} annotation="antConnectivityGamma" name="gamma_connectivity.jpg" />
                </div>
            </div>
        </>
    );
};

export default AnalysisConnectivity;