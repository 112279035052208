import React from 'react';
import { useState } from 'react';
import AdminLayout from '../../Components/adminLayout/AdminLayout';
import "./style.scss";
import { useForm } from 'react-hook-form';
import CompAnalyzeSingle from './CompAnalyzeSingle';
import CompAnalyzeMultiple from './CompAnalyzeMultiple';

const ViewClinicalAnalysis = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const [analyzeSingle, setAnalyzeSingle] = useState(true);
    const [showUpload, setShowUpload] = useState(false);

    const onSingleAnalysis = () => {
        setAnalyzeSingle(true);
        setShowUpload(true)
    }
    const onMultipleAnalysis = () => {
        setAnalyzeSingle(false);
        setShowUpload(true)
    }
    const restartProcess = () => {
        setAnalyzeSingle(false);
        setShowUpload(false);
    }

    return (
        <>
            <AdminLayout activeMenu={2} title="Clinical Data Analysis">
                {showUpload ? <div className="container px-4 d-flex">

                    <div className="d-flex mt-4 align-items-center justify-content-center">
                        <h3 className="text-center font-bold mb-">
                            Want to start the process again?
                        </h3>
                        <button onClick={restartProcess} className="btn btn-primary mx-4">
                            Try New
                        </button>
                    </div>
                </div> : <div className="container p-4">
                    <h3 className="text-center font-bold mb-">
                        How do you want to run the analysis?
                    </h3>
                    <div className="d-flex mt-4 justify-content-center">
                        <button onClick={onMultipleAnalysis} className="btn btn-primary mx-4">
                            Analyze a group of subjects
                        </button>
                        <button onClick={onSingleAnalysis} className="btn btn-primary mx-4">
                            Analyze a particular subject
                        </button>
                    </div>
                </div>}
                <div className="container p-4">
                    {analyzeSingle && showUpload && <CompAnalyzeSingle />}
                </div>
                <div className="container p-4">
                    {!analyzeSingle && showUpload && <CompAnalyzeMultiple />}
                </div>
            </AdminLayout>
        </>
    );
};

export default ViewClinicalAnalysis;