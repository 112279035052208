import React, { useEffect, useState } from 'react';
import StorageImg from '../../Components/StorageImg/StorageImg';
import { FaRegArrowAltCircleRight, FaRegArrowAltCircleLeft } from "react-icons/fa"

const AnalysisSourceLocalization = ({ analysisData, analysisID }) => {
    const [sourceImage, setSourceImage] = useState("source_default.jpg");

    const [slTime, setSlTime] = useState("tmin");
    const [slView, setSlView] = useState(null);
    const [slHeme, setSlHeme] = useState(null);

    useEffect(() => {
        if (slView && slHeme) {
            if (slTime === "0.02" || slTime === "0.015") {
                setSourceImage(null)

                setTimeout(() => {
                    setSourceImage(`source_${slTime}_${slView}_${slHeme}.jpg`);

                }, 50)
            }

        }
    }, [slTime, slView, slHeme])

    const onHemisphereChange = (e) => {
        setSlHeme(e.target.value);
    }
    const onArrowClick = (arrow)=>{
        if(arrow==="left"){
            if(slTime==="0.02"){
                setSlTime("0.015")
            }
        }else if(arrow==="right"){
            if(slTime==="0.015"){
                setSlTime("0.02")
            }else if(slTime==="tmin"){
                setSlTime("0.015")
            }
        }

    }

    return (
        <>
            <div className="row">
                <div className="col-md-5">
                    {sourceImage && <StorageImg analysisData={analysisData} annotation="antSourceLocalization" name={sourceImage} />}
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-6 ">
                    {/* Graph: {sourceImage} */}

                    <div className="row mt-4">
                        <div className="col-7">
                            <label className="mb-2 font-weight-bold">View</label>
                            <select onChange={(e) => setSlView(e.target.value)} name="view" className="custom-select">
                                <option value="lat">Lateral</option>
                                <option value="med">Medial</option>
                                <option value="ros">Rostral</option>
                                <option value="cau">Caudal</option>
                                <option value="dor">Dorsal</option>
                                <option value="ven">Ventral</option>
                                <option value="fro">Frontal</option>
                                <option value="par">Partial</option>
                            </select>
                        </div>
                        <div className="col-7">
                            <label className="mb-2 font-weight-bold">Hemisphere</label>
                            <select onChange={(e) => onHemisphereChange(e)} name="view" className="custom-select">
                                <option value="lh">Left</option>
                                <option value="rh">Right</option>

                            </select>
                        </div>
                        <div className="col-7 mt-4 d-flex justify-content-center">
                            <button onClick={()=>onArrowClick("left")} className="btn-sm font-18 btn btn-secondary">
                                <FaRegArrowAltCircleLeft />
                            </button>
                            <button className="btn-sm mx-3 btn btn-primary">
                                {slTime}
                            </button>
                            
                            <button onClick={()=>onArrowClick("right")} className="btn-sm font-18 btn btn-secondary">
                                <FaRegArrowAltCircleRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalysisSourceLocalization;