import React from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import { useHistory } from 'react-router-dom';
import ModalLoading from '../../Components/common/PreLoaders/ModalLoading';
import fireApp, { analysisCollectionRef, } from "../../hooks/firebase/fire-app";
import { useAuth } from '../../hooks/useAuth';

const CompAnalyzeSingle = () => {
    const analysisRef = analysisCollectionRef.doc()
    const { currentUser } = useAuth();

    const [analysisID, setAnalysisID] = useState(analysisRef.id)
    const history = useHistory();
    const [modalText, setModalText] = useState("Uploading Data to Server")
    const [modalShow, setModalShow] = React.useState(false);

    const loadingData = () => {
        setModalText("Upload complete! Processing Data.")
        setTimeout(() => {
            setModalShow(false)
            history.push("/clinical-data-analysis/" + analysisID)
        }, 5500)
    }
    const serverBaseUrl = "http://127.0.0.1:8000/time-analysis/"
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false)
    const serviceStorageRef = fireApp.storage().ref().child(`analysis/${analysisID}`);

    const formSubmission = (data, e) => {
        setModalShow(true)
        setLoading(true)
        console.log(data);
        const rawFile = data.rawFile[0];
        const rawFileRef = serviceStorageRef.child(rawFile.name);
        console.log("Uploading...");
        const profile = {
            name: data.name,
            email: data.email,
            createdAt: new Date(),
            age: data.age,
            sex: data.sex,
            analyst: currentUser ? currentUser.email : "shahjahan.swajan@myelinh.com"
        }
        rawFileRef.put(rawFile).then(res => {
            rawFileRef.getDownloadURL().then(res => {
                profile.rawData = res
                analysisCollectionRef.doc(analysisID).set(profile).then((doc) => {
                    // sendRawDataToServer(profile)
                    console.log("added")
                    setLoading(false)
                    sendRawDataToServer({analysisID: analysisID, rawData: profile.rawData})
                    loadingData()

                })
            }).catch(err => console.log(err))

        })

    }
    const sendRawDataToServer = (data) => {
        // setLoading(true)
        console.log("sending");
        fetch(serverBaseUrl, {
            method: "POST",
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                console.log("retrived");
                console.log(data.data);
                // setIsAdmin(data)
                setLoading(false)
                setModalShow(false)
            })
    }
    return (
        <>
            <div className="mt-3 container">
                <form onSubmit={handleSubmit(formSubmission)} >
                    <div className="row">
                        <div className="col-12">
                            {analysisID}
                        </div>
                        <div className="col-7 mb-4">
                            <label className="mb-2 font-weight-bold">User Name </label>
                            <input type="text" name="name" placeholder="Name" id="name" className="form-control py-4" {...register("name", { required: true })} />
                        </div>
                        <div className="col-4 mb-4">
                            <label className="mb-2 font-weight-bold">Email</label>
                            <input type="email" name="email" placeholder="email" id="email" className="form-control py-4" {...register("email")} />
                        </div>
                        <div className="col-4 mb-4">
                            <label className="mb-2 font-weight-bold">Age</label>

                            <input type="age" name="age" placeholder="age" id="age" className="form-control py-4" {...register("age")} />
                        </div>
                        <div className="col-4 mb-4">
                            <label className="mb-2 font-weight-bold">Sex</label>
                            <select name="category" {...register("sex")} className="custom-select">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>

                        </div>
                        <div className="col-4"></div>
                        <div className="col-4 ">
                            <label className="mb-2 font-weight-bold">Upload Image </label> <br />
                            <div className="upload-btn-wrapper">
                                <div className="up-btn btn-outline-success py-2 px-5 btn">
                                    <IconContext.Provider value={{ size: "1.5rem" }}>
                                        <AiOutlineCloudUpload />
                                    </IconContext.Provider> upload
                                    <input type="file" {...register("rawFile")} name="rawFile" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 mb-4">
                            <label className="mb-2 font-weight-bold">Preprocess</label>
                            <select name="category" {...register("preprocess")} className="custom-select">
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>

                        </div>
                        <div className="col-12 mt-4">
                            <button type="submit" className="btn btn-primary px-5 py-3">Start Analysis {loading && <Spinner animation='grow' size='small' />}</button>
                        </div>
                    </div>
                </form>
            </div>
            <ModalLoading show={modalShow} modalText={modalText} onHide={() => setModalShow(false)} />
        </>
    );
};

export default CompAnalyzeSingle;