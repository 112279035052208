import React from 'react';
import { Spinner } from 'react-bootstrap';
import "./loader.scss";

const SectionLoading = () => {
    return (
        <>
            <section className="section-loader d-flex align-items-center justify-content-center">
                <div className="text-primary">
                    <h3 className="font-bold">Myelin-H</h3>
                    <Spinner animation='grow' />
                </div>
            </section>
        </>
    );
};

export default SectionLoading;