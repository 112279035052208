import React from 'react';
import { Link } from 'react-router-dom';
import "./sidebar.scss"
import fireApp, { registeredUsersCollection } from "../../hooks/firebase/fire-app";
import { useHistory } from 'react-router-dom';

const AdminSidebar = ({ sideBarItems }) => {
    const history = useHistory();
    const LogoBrand = "/logo/white.png"
    const signOutFunction = () => {
        fireApp.auth().signOut().then(res => {
            // window.location.reload()
            // setHasAccess(false);
            // setCurrentUser(null);
            // localStorage.removeItem("licenseKeyToken")
            // setCurrentUser(null);
            history.push("/")
        }).catch(err => {

        })
    }
    return (
        <>
            <div className="pt-5 bg-primary px-4 admin-sidebar" >
                <div className='mb-4'>
                    <img src={LogoBrand} alt="" className="img-fluid" />
                </div>
                <hr />
                <SidebarItem data={sideBarItems[0]}/>
                <SidebarItem data={sideBarItems[1]}/>
                <SidebarItem className="mt-4" data={sideBarItems[2]}/>
                <SidebarItem data={sideBarItems[3]}/>
                <SidebarItem className="mt-5" data={sideBarItems[4]}/>
                <SidebarItem data={sideBarItems[5]}/>
                <SidebarItem data={sideBarItems[6]}/>
                <SidebarItem data={sideBarItems[7]}/>
                <button onClick={signOutFunction} className="btn-primary btn-block btn mt-5">Sign Out</button>
                {/* <SidebarItem data={sideBarItems[8]}/> */}
                {/* {
                    sideBarItems.map(item => (
                        <a href={item.url} className={"d-block  text-white sidebar-item py-2 " + item.status} >
                            {item.icon} <p className="ml-1 d-inline-block ">{item.text} </p>
                        </a>
                    ))
                } */}
            </div>


        </>
    );
};

export default AdminSidebar;

const SidebarItem = ({ data, className }) => {
    return (
        <>
            <Link to={data.url} className={`d-block  text-white sidebar-item py-2 ${data.status} ${className && className}`} >
                {data.icon} <p className="ml-1 d-inline-block ">{data.text} </p>
            </Link>
        </>
    )
}